import React, { useState, useEffect, useRef } from "react";
import ActiveASCArrow from './activeSortAsc.svg';
import DESCArrow from './sortDesc.svg';
import NoSortArrows from './noSort.svg';
import './tableCompStyle.css'

//This component is table populated by userdata. The data in the table can be sorted and will be paginated; 
const TableComp = (props) => {
    // TableComp is expecting the following format for props:

    // handleSetData: A function that updates the data when sorting occurs.

    // data: An array of objects that contains the data to be displayed in the table rows.

    // tableHeaders: An array of objects used to display the table columns.
    // This array is generated in the file where TableComp is imported, based on the structure of the data.
    // Basic example of how the array can be constructed:
    // let arr = [];
    // Object.entries(data[0]).forEach(([key]) => {
    //     arr.push({
    //         id: `pref${key}`, // A unique ID for each header
    //         title: key.split('_')[0], // Extract the first part of the key (adjust as needed)
    //         name: key, // The full key used in the data
    //         sort: false // Default sorting state for each column
    //     });
    // });
    // Example of the resulting array: 
    // [{ id: "prefEvent_VC", name: "Event_VC", sort: true, title: "Event" }]

    // isUsableData: A boolean representing whether we are receiving an array with the correct format 
    // but no actual data, or an array with usable data.

    // itemsPerPage: An expected Int that represent how many rows (not counting headers)
    //  will be displayed in each page.

    //sortable: a boolean representing if the table contents can be sorted or not. When true a drop down
    //for sorting will be included on the mobile view

    const { handleSetData, data, tableHeaders, isUsableData, itemsPerPage, sortable } = props;
    const [prevSort, setPrevSort] = useState("")
    const [sortASC, setSortASC] = useState(true)
    const [pageIndex, setPageIndex] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [canPreviousPage, setCanPreviousPage] = useState(false);
    const [canNextPage, setCanNextPage] = useState(false);
    const [currentData, setCurrentData] = useState(data)
    const [pages, setPages] = useState([])
    const [sortingOptions, setSortingOptions] = useState([{
        title: "",
        id: ""
    }])
    const [sorting, setSorting] = useState(false)
    const [maxWidth, setMaxWidth] = useState(0);

    const divRef = useRef(null);

    const scrollToTop = () => {
        if (divRef.current) {
            divRef.current.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scrolling
        }
    };

    // Function to navigate to a specific page
    const gotoPage = (page) => {
        scrollToTop();
        setPageIndex(page);
    };

    // Function to go to the previous page
    // eslint-disable-next-line
    const previousPage = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1);
        }
    };

    // Function to go to the next page
    // eslint-disable-next-line
    const nextPage = () => {
        if (pageIndex < totalPages - 1) {
            setPageIndex(pageIndex + 1);
        }
    };

    const handleSort = (e, isMobile) => {
        e.preventDefault();
        setSorting(true)
        // console.log(e.target.id);
        let target = e.target.id;
        if (isMobile) {
            target = e.target.value
        }
        let sortedData;
        let prevSortDirection = sortASC;
        if (target !== "mobileSortBtn") {
            const sortingObj = tableHeaders.find(x => x.id === target)
            if (target !== prevSort) {
                sortedData = data.sort((a, b) => a[sortingObj.name].localeCompare(b[sortingObj.name]))
                setSortASC(true)
            } else if (target === prevSort) {

                if (prevSortDirection) {

                    sortedData = data.sort((a, b) => b[sortingObj.name].localeCompare(a[sortingObj.name]))
                } else {

                    sortedData = data.sort((a, b) => a[sortingObj.name].localeCompare(b[sortingObj.name]))
                }
                setSortASC(prevState => !prevState)
            }
            setPrevSort(target)
        } else {
            const sortingObj = tableHeaders.find(x => x.id === prevSort)
            if (prevSortDirection) {

                sortedData = data.sort((a, b) => b[sortingObj.name].localeCompare(a[sortingObj.name]))
            } else {

                sortedData = data.sort((a, b) => a[sortingObj.name].localeCompare(b[sortingObj.name]))
            }
            setSortASC(prevState => !prevState)
        }
        setPageIndex(0)
        handleSetData(sortedData)
        setSorting(false)
    }

    useEffect(() => {
        // Calculate the maximum width based on the longest title
        const longestTitleLength = tableHeaders.reduce((max, header) => Math.max(max, header.title.length), 0);

        // Estimate width based on character count
        const charWidth = 16; // Adjust this based on your font-size and font-family
        const calculatedWidth = longestTitleLength * charWidth + 10; // Add padding (e.g., 20px)

        setMaxWidth(calculatedWidth); // Update the maxWidth state
        // console.log('Calculated maxWidth:', calculatedWidth);
    }, [tableHeaders, data])



    useEffect(() => {
        setPrevSort("")
        setSortASC(true)
        setPageIndex(0)
        setTotalPages(Math.ceil(data.length / itemsPerPage))
        let sortArr = [];
        tableHeaders.forEach((x) => {
            if (x.sort) {
                sortArr.push({ title: x.title, id: x.id })
            }
        })
        setSortingOptions(sortArr)
    }, [data, itemsPerPage, tableHeaders])

    useEffect(() => {
        scrollToTop()
        const sI = pageIndex * itemsPerPage;
        const eI = sI + itemsPerPage;
        const CD = data.slice(sI, eI)
        if (pageIndex === 0) {
            setPages([0, 1, 2].filter(page => page < totalPages))
        } else if (pageIndex === totalPages - 1) {
            setPages([totalPages - 3, totalPages - 2, totalPages - 1].filter(page => page >= 0))
        } else {
            const thirdPage = Math.max(0, pageIndex - 1);
            const secondPage = Math.max(0, pageIndex - 2);
            // const startPage = Math.max(0, pageIndex - 3);
            const fifthPage = Math.min(totalPages - 1, pageIndex + 1);
            const sixthPage = Math.min(totalPages - 1, pageIndex + 2);
            // const endPage = Math.min(totalPages - 1, pageIndex + 3);
            setPages([secondPage, thirdPage, pageIndex, fifthPage, sixthPage].filter((page, index, self) => page < totalPages && self.indexOf(page) === index))
        }
        setCurrentData(CD)
    }, [pageIndex, prevSort, sortASC, data, totalPages, itemsPerPage])

    useEffect(() => {
        setCanPreviousPage(pageIndex > 0)
        setCanNextPage(pageIndex < totalPages - 1)
    }, [pageIndex, totalPages])


    return (
        <>
            <div className='details-box' ref={divRef}>
                <div className="col-lg d-none d-lg-block">
                    <table className="table">
                        <thead style={{ position: "sticky", top: "0px", background: "white", borderBottom: "1px solid black", zIndex: 1 }}>
                            <tr>
                                {tableHeaders.map((x, index) =>
                                    x.sort ?
                                        (<th className='table-detail' key={index}>
                                            <div className="sort-line">
                                                <p style={{ margin: "0" }}>{x.title}</p>
                                                {!isUsableData &&
                                                    <p style={{ margin: "0" }} id={x.id} onClick={(e) => handleSort(e)}>
                                                        {(prevSort === x.id && sortASC &&
                                                            <img src={DESCArrow} alt="sortArrow" id={x.id} />
                                                        )}
                                                        {(prevSort !== x.id &&
                                                            <img src={NoSortArrows} alt="sortArrow" id={x.id} />
                                                        )}
                                                        {(prevSort === x.id && !sortASC &&
                                                            <img src={ActiveASCArrow} alt="sortArrow" id={x.id} />
                                                        )}
                                                    </p>
                                                }
                                            </div>
                                        </th>)
                                        :
                                        (<th className='table-detail' key={index}>{x.title}</th>)
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((x, index) =>
                                <tr key={index}>
                                    {tableHeaders.map((y, idx) => (
                                        <td className='table-detail' key={idx}>
                                            {x[y.name]}
                                        </td>
                                    ))}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="col col-sm d-block d-lg-none">
                    {sortable &&
                        <div className="row" style={{ background: "white", borderBottom: "1px solid black", margin: "0", paddingTop: "5px", position: "sticky", top: "0", zIndex: "10" }}>
                            <div className="col-9 col-md-10">
                                {/* <label htmlFor="prefSort" className="form-label service-form-label2">
                                    Sort By:
                                </label> */}
                                <select className="form-select service-form-control" id="prefSort" value={prevSort} onChange={(e) => handleSort(e, true)} style={{ marginBottom: "5px" }}>
                                    <option value="" disabled>Sort</option>
                                    {sortingOptions[0].title !== "" && sortingOptions.map((state, index) =>
                                        <option key={index} value={state.id}>{state.title} </option>
                                    )}
                                </select>
                            </div>
                            <div className="col-3 col-md-2 sort-div" id="mobileSortBtn">
                                {(!sorting && prevSort !== "" && sortASC &&
                                    <button type="button" className="sort-button w-100" id="mobileSortBtn" onClick={(e) => handleSort(e)}>
                                        <img src={DESCArrow} alt="sortArrow" id="mobileSortBtn" />
                                    </button>

                                )}
                                {(!sorting && prevSort !== "" && !sortASC &&
                                    <button type="button" className="sort-button w-100" id="mobileSortBtn" onClick={(e) => handleSort(e)}>
                                        <img src={ActiveASCArrow} alt="sortArrow" id="mobileSortBtn" />
                                    </button>
                                )}
                            </div>
                        </div>
                    }
                    <div className="row" style={{ margin: "0" }}>
                        {currentData.map((x, index) =>
                            <div key={index} style={{ borderBottom: "2px solid darkgray", margin: 0, background: index % 2 === 0 ? "white" : "#ececec" }}>
                                {tableHeaders.map((y, idx) => (
                                    <div className="row" key={"row" + idx} style={{ borderBottom: ".5px solid darkgray", display:"flex"}}>
                                        <div
                                            className="col mobile-col-info"
                                            key={"title" + idx}
                                            style={{
                                                minWidth: `${Math.min(maxWidth, window.innerWidth * .40)}px`,
                                                maxWidth: `${Math.min(maxWidth, window.innerWidth * .40)}px`,
                                                flexShrink: 0
                                            }}
                                        >
                                            <b>{y.title}</b>
                                        </div>
                                        <div
                                            className="col mobile-col-info" key={idx}>
                                            {x[y.name]}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/*Pagination for the table: */}
            <nav style={{ marginBottom: "-25px", marginTop: "10px" }}>
                <ul className="pagination justify-content-center">
                    {/* First Page Button */}
                    <li className="page-item">
                        <button
                            className={`page-link ${!canPreviousPage ? 'disabled' : ''}`}
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            {/* First */}
                            <span aria-hidden="true">&laquo;</span>
                        </button>
                    </li>

                    {/* Previous Page Button */}
                    {/* <li className="page-item">
                        <button
                            className={`page-link ${!canPreviousPage ? 'disabled' : ''}`}
                            onClick={previousPage}
                            disabled={!canPreviousPage}
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </button>
                    </li> */}
                    {/* Dynamically Generated Page Numbers */}
                    {pages.map(page =>
                        <li key={page} className="page-item">
                            <button
                                className={`page-link ${page === pageIndex ? 'active' : ''}`}
                                onClick={() => gotoPage(page)}
                            >
                                {page + 1}
                            </button>
                        </li>
                    )}

                    {/* Next Page Button */}
                    {/* <li className="page-item">
                        <button
                            className={`page-link ${!canNextPage ? 'disabled' : ''}`}
                            onClick={nextPage}
                            disabled={!canNextPage}
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </button>
                    </li> */}

                    {/* Last Page Button */}
                    <li className="page-item">
                        <button
                            className={`page-link ${!canNextPage ? 'disabled' : ''}`}
                            onClick={() => gotoPage(totalPages - 1)}
                            disabled={!canNextPage}
                        >
                            {/* Last */}
                            <span aria-hidden="true">&raquo;</span>
                        </button>
                    </li>
                </ul>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-18px", marginBottom: "6px" }}><span>{pageIndex + 1} of {totalPages}</span></div>
            </nav>
        </>
    )
}
export default TableComp;