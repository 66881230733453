import React from "react";

//This component is a input box. It will filter through the data and return the objects that match the entered text.
const SearchBarComp = ({ data, handleSetData, handleUnSearchData, emptyData, setNoSearchResults, searchVal, handleSearchVal }) => {
    // SearchBarComp is expection the following format for the args.

    // data: An array of objects that contains the data to be filtered.
    // ex.) [{Event_VC: "abc", Farm_VC: "abc", Field_VC: "abc", Grower_VC: "abc", Lab_VC: "abc", SampleCount: "1", SampleType_VC: "abc", Status_DT: "01-01-1111", Status_VC: "abc"}...]

    // handleSetData: A function that updates the data when filtering occurs.

    // handleUnSearchData: A function that returns the orginal unfiltered data when the search box is emptied

    // emptyData: should match the format of data but is only one object in an array with all the values being empty strings
    // ex.) [{Event_VC: "", Farm_VC: "", Field_VC: "", Grower_VC: "", Lab_VC: "", SampleCount: "", SampleType_VC: "", Status_DT: "", Status_VC: ""}]
    
    // setNoSearchResults: handles updating a boolean variable that represents if the filtered data returned any matching objects or not

    // searchVal: a variable that holds the value of what is typed into the search input box

    // handleSearchVal: handles updating the searchVal.

    
    function getNestedStrings(obj, level) {

        let s = [];

        if (obj) {
            if (level === 1) { s = [] }
            const valArr = Object.values(obj);
            const strArr = valArr.filter((o) => { return (typeof o === 'string') });
            const objArr = valArr.filter((o) => { return (typeof o === 'object') });
            if (strArr.length > 0) { s = [...s, ...strArr]; };
            objArr.forEach((x) => { getNestedStrings(x, level + 1) });
            if (level === 1) { return s; };
        }
    };

    const handleSearch = (e) => {
        let searchString;
        if (e !== undefined) {
            e.preventDefault();
            searchString = e.target.value.toLowerCase();
        } else {
            searchString = "";
        }
        handleSearchVal(searchString)
        if (searchString.length) {
            const res = (data.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(searchString.toLowerCase()))
                }))
            }));
            if (res.length > 0) {
                setNoSearchResults(false)
                handleSetData(res)  // show result object(s)
            } else {
                setNoSearchResults(true)
                handleSetData(emptyData)
            }


        } else {
            setNoSearchResults(false)
            handleUnSearchData()
        }
    }

    const handleSearchBug = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }

    return (
        <>
            <label htmlFor="prefSearch" className="form-label">
                Search
            </label>
            <input
                type="text"
                className="form-control"
                id="prefSearch"
                style={{ marginTop: "-10px" }}
                value={searchVal}
                onChange={(e) => handleSearch(e)}
                onKeyDown={handleSearchBug}
            />
        </>
    )
}
export default SearchBarComp;