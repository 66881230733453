import React from 'react';
import ReactDOM from 'react-dom/client'; //Import ReactDOM for rendering the React components into the DOM
import AppWrapper from './AppWrapper'; //Import the main AppWrapper component
import { BrowserRouter } from 'react-router-dom'; //Import BrowserRouter for routing

// Get the root container element from the DOM
const container = document.getElementById('root');

// Create a root element for rendering
const root = ReactDOM.createRoot(container);

// Render the application wrapped with BrowserRouter
root.render(
    <BrowserRouter>
        <AppWrapper />
    </BrowserRouter>

);