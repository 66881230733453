import React, { useState, useEffect } from 'react';

import { TableComp, SearchBarComp } from './components';

import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const timeLineOptions = [
    "Past Week",
    "Past Month",
    "Past 3 Months",
    "Past 6 Months"
];
const emptyData = [{
    Grower_VC: "",
    Farm_VC: "",
    Field_VC: "",
    Event_VC: "",
    Lab_VC: "",
    SampleCount: "",
    SampleType_VC: "",
    Status_DT: "",
    Status_VC: ""
}]

const SampleStatusTable = (props) => {
    const {
        accountsForUserName,
        timeline,
        dataSamples,
        handleUpdateAccountSelected,
        handleUpdateTimeLineSelected,
        sampleRequestObj,
        gettingUserAccounts,
        userName,
        loadingSamples,
        loadingSampleMessage,
        searchVal,
        handleSearchVal
    } = props;

    const [data, setData] = useState(emptyData)

    const [isUsableData, setIsUsableData] = useState(true)
    const [itemsPerPage, setItemsPerPage] = useState(0)

    const [tableHeaders, setTableHeaders] = useState([{
        id: "",
        title: "",
        name: "",
        sort: false
    }])

    //set gets passed to searchBarComp and const gets used for a conditioned message in the return below
    const [noSearchResults, setNoSearchResults] = useState(false)


    const handleSetData = (newData) => {
        //updates data based on sort/filter
        setData(newData)
    }

    const handleUnSearchData = () => {
        //resets data back to what it was before sort/filter. (what we get based on account and date range only)
        setData(dataSamples)
    }





    useEffect(() => {
        let newArr = [];
        if (dataSamples[0].Lab_VC !== "") {
            setIsUsableData(false)
            setItemsPerPage(15)
        } else {
            setIsUsableData(true)
        }
        setData(dataSamples)
        Object.entries(dataSamples[0]).forEach(([key, value]) => {
            switch (key) {
                case 'Event_VC':
                case 'Farm_VC':
                case 'Field_VC':
                case 'Grower_VC':
                    newArr.push({
                        id: `pref${key}`,
                        title: key.split('_')[0],
                        name: key,
                        sort: true
                    })
                    break;
                case 'SampleCount':
                    newArr.push({
                        id: `pref${key}`,
                        title: 'Sample Count',
                        name: key,
                        sort: false
                    })
                    break;
                case 'SampleType_VC':
                    newArr.push({
                        id: `pref${key}`,
                        title: 'Sample Type',
                        name: key,
                        sort: false
                    })
                    break;
                case 'Status_DT':
                    newArr.push({
                        id: `pref${key}`,
                        title: 'Status Date',
                        name: key,
                        sort: true
                    })
                    break;
                default:
                    newArr.push({
                        id: `pref${key}`,
                        title: key.split('_')[0],
                        name: key,
                        sort: false
                    })
                    break;
            }
        });
        setTableHeaders(newArr)
    }, [dataSamples])

    return (
        <>
            <div className="row" >
                <div className="col-12 col-lg-8">
                    <label htmlFor="prefAccount" className="form-label service-form-label2" style={{ marginBottom: "-16px" }}>
                        Accounts
                    </label>
                    <select className="form-select service-form-control" id="prefAccount" value={sampleRequestObj.accountName} onChange={(e) => handleUpdateAccountSelected(e)}>
                        <option value="" disabled>--Select Account--</option>
                        {!gettingUserAccounts && accountsForUserName[0].AccountName !== "" && accountsForUserName.map((state, index) =>
                            <option key={index} value={state.AccountName}>&#40;{state.AccountID}&#41; {state.AccountName}</option>
                        )}
                        {gettingUserAccounts && userName !== "" &&
                            <option value="" disabled>loading accounts...</option>
                        }
                        {!gettingUserAccounts && userName !== "" && accountsForUserName[0].AccountName === "" &&
                            <option value="" disabled>No account under this username...</option>
                        }
                        {gettingUserAccounts && userName === "" &&
                            <option value="" disabled>Cannot get accounts without username...</option>
                        }
                    </select>
                </div>
                <div className="col-12 col-lg-4">
                    <label htmlFor="prefFiscalYear" className="form-label service-form-label2" style={{ marginBottom: "-16px" }}>
                        Time Range
                    </label>
                    <select className="form-select service-form-control" id="prefFiscalYear" value={timeline} onChange={(e) => handleUpdateTimeLineSelected(e)}>
                        <option value="" disabled>--Select Time Range--</option>
                        {timeLineOptions.map((state, index) =>
                            <option key={index} value={state}>{state}</option>
                        )}
                    </select>
                </div>
                <div className='col-12 col-lg-8'>
                    <SearchBarComp
                        data={dataSamples}
                        handleSetData={handleSetData}
                        handleUnSearchData={handleUnSearchData}
                        emptyData={emptyData}
                        setNoSearchResults={setNoSearchResults}
                        searchVal={searchVal}
                        handleSearchVal={handleSearchVal}
                    />
                </div>
            </div>

            {loadingSamples && sampleRequestObj.accountID === "" &&
                <div className="sample-message">
                    {loadingSampleMessage}
                </div>
            }
            {loadingSamples && sampleRequestObj.accountID !== "" &&
                <div className="sample-message">
                    Loading...
                </div>
            }
            {!loadingSamples && data[0].Lab_VC === "" && !noSearchResults &&
                <div className="sample-message">
                    {loadingSampleMessage}
                </div>
            }
            {!loadingSamples && data[0].Lab_VC === "" && noSearchResults &&
                <div className="sample-message">
                    No Samples Match This Search
                </div>
            }
            {!loadingSamples && data && data[0].Lab_VC !== "" &&
                <TableComp
                    handleSetData={handleSetData}
                    data={data}
                    tableHeaders={tableHeaders}
                    isUsableData={isUsableData}
                    itemsPerPage={itemsPerPage}
                    sortable={true}
                />
            }
        </>
    );
}

export default SampleStatusTable;