import React from "react";
import './cardComp.css'

// This component is a styled card that will neatly display a specified component. 
const CardComp = ({ cardBodyComp }) => {
    // The cardComponent only expects one possible prop:
    // the cardBodyComp prop should be a component that
    // will be displayed within the card.

    return (
        <>
            <div className="container-fluid px-2" style={{ "marginTop": "5px" }}>
                <div className="card mx-auto">
                    <div className="card-body" style={{marginTop:"-14px"}}>
                        {/*Another comp can go here: */}
                        {cardBodyComp}
                    </div>
                </div>
            </div>
        </>
    )
}
export default CardComp;