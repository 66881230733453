import React, { useState, useEffect } from 'react';
import SampleStatusTable from './SampleStatusTable'
import { ServiceHeader, CardComp } from './components';
import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const fetchURI = 'https://sample-status-express-495656309447.us-central1.run.app/'

//labs use UTC for time
function formatDate(date) {
    const day = String(date.getUTCDate()).padStart(2, '0');  // Get day and pad with leading 0
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');  // Get month (0-indexed), pad with 0
    const year = date.getUTCFullYear();  // Get full year (4 digits)

    return `${month}-${day}-${year}`;
}

function getLastWeek() {
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getUTCDate() - 7);  // Subtract 7 days from current date
    return formatDate(lastWeek);
}

function getOneMonthAgo() {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getUTCMonth() - 1);  // Subtract 1 month from current date
    return formatDate(oneMonthAgo);
}

function getThreeMonthsAgo() {
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getUTCMonth() - 3);  // Subtract 3 months from current date
    return formatDate(threeMonthsAgo);
}

function getSixMonthsAgo() {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getUTCMonth() - 6);  // Subtract 6 months from current date
    return formatDate(sixMonthsAgo);
}

const AppWrapper = () => {
    const today = formatDate(new Date());
    const lastWeek = getLastWeek();
    const oneMonthAgo = getOneMonthAgo();
    const pastThreeMonths = getThreeMonthsAgo();
    const pastSixMonths = getSixMonthsAgo();

    const [userName, setUserName] = useState("");
    const [sampleRequestObj, setSampleRequestObj] = useState({
        accountID: "",
        accountName: "",
        startDate: lastWeek,
        endDate: today
    })
    const [accountsForUserName, setAccountsForUserName] = useState([{
        AccountID: "",
        AccountName: ""
    }])

    const [timeline, setTimeline] = useState("Past Week");
    const [gettingUserAccounts, setGettingUserAccounts] = useState(true)
    const [dataSamples, setDataSamples] = useState([{
        Grower_VC: "",
        Farm_VC: "",
        Field_VC: "",
        Event_VC: "",
        Lab_VC: "",
        SampleCount: "",
        SampleType_VC: "",
        Status_DT: "",
        Status_VC: ""
    }])
    const [loadingSamples, setLoadingSamples] = useState(false)
    const [loadingSampleMessage, setLoadingSamplesMessage] = useState("Select Account To Show Samples")

    const [searchVal, setSearchVal] = useState("") //goes with the searchBarComp; gets set back to empty string when making a new data request
    // function gets a specific cookie
    function getCookie(cName) {
        const name = cName + "=";
        // const cDecoded = decodeURIComponent(document.cookie); //to be careful
        const cArr = document.cookie.split('; ');
        let res;
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
        })
        return res
        // return "agro" //for testing
    }

    const fetchData = async () => {
        const UN = await getCookie("username")
        setUserName(UN)
        if (UN !== "" && UN !== undefined) {
            // console.log('in Fetch data');
            const getAccountsForUserFetch = await fetch(`${fetchURI}getUserAccounts/${UN}`, { method: 'GET' });
            const getAccountsForUserJson = await getAccountsForUserFetch.json();
            // console.log(getAccountsForUserJson);
            if (getAccountsForUserJson.length > 0) {
                setAccountsForUserName(getAccountsForUserJson)
            } else {
                setAccountsForUserName([{
                    AccountID: "",
                    AccountName: ""
                }])
            }
            setGettingUserAccounts(false)
        }
    }

    const getSampleData = async () => {
        setLoadingSamples(true)
        const getSamplesFetch = await fetch(`${fetchURI}getSamplesStatus?accountID=${sampleRequestObj.accountID}&startDate=${sampleRequestObj.startDate}&endDate=${sampleRequestObj.endDate}`, {
            method: 'GET'
        });
        const getSampleJson = await getSamplesFetch.json()
        // console.log(getSampleJson);
        if (getSampleJson.length > 0) {
            let getSampleJsonWithFormatedDate = getSampleJson.map(item => {
                item.Status_DT = formatDate(new Date(item.Status_DT))
                return item
            })
            setDataSamples(getSampleJsonWithFormatedDate);
            setLoadingSamples(false);
        } else {
            setLoadingSamples(false);
            setLoadingSamplesMessage("No Samples For Account/Time Frame")
        }
        setLoadingSamples(false)
    }
    const handleUpdateAccountSelected = (e) => {
        const value = e.target.value;
        const as = accountsForUserName.find(x => x.AccountName === value)
        setSearchVal("")
        setSampleRequestObj(prevObj => {
            return { ...prevObj, accountID: as.AccountID, accountName: as.AccountName }
        })
    }
    const handleUpdateTimeLineSelected = (e) => {
        const value = e.target.value;
        setTimeline(value)
        setSearchVal("")
        switch (value) {
            case "Past Week":
                setSampleRequestObj(prevObj => {
                    return { ...prevObj, startDate: lastWeek }
                })
                break;
            case "Past Month":
                setSampleRequestObj(prevObj => {
                    return { ...prevObj, startDate: oneMonthAgo }
                })
                break;
            case "Past 3 Months":
                setSampleRequestObj(prevObj => {
                    return { ...prevObj, startDate: pastThreeMonths }
                })
                break;
            case "Past 6 Months":
                setSampleRequestObj(prevObj => {
                    return { ...prevObj, startDate: pastSixMonths }
                })
                break;

            default:
                break;
        }
    }

    const handleSearchVal = (value) => {
        setSearchVal(value)
    }



    // Next two lines are for when using a cookie
    useEffect(() => {
        window.addEventListener("DOMContentLoaded", fetchData())
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        //fetch for samples:
        if (sampleRequestObj.accountID !== "") {
            setDataSamples([{
                Grower_VC: "",
                Farm_VC: "",
                Field_VC: "",
                Event_VC: "",
                Lab_VC: "",
                SampleCount: "",
                SampleType_VC: "",
                Status_DT: "",
                Status_VC: ""
            }])
            getSampleData()
        }
        // eslint-disable-next-line
    }, [, sampleRequestObj])


    return (
        <div style={{ background: "rgb(240,242,244", height: "100vh" }}>
            <ServiceHeader
                title={'Sample Status'}
            />
            <div className="content-routes">
                <CardComp
                    cardBodyComp={<SampleStatusTable
                        accountsForUserName={accountsForUserName}
                        timeline={timeline}
                        dataSamples={dataSamples}
                        handleUpdateAccountSelected={handleUpdateAccountSelected}
                        handleUpdateTimeLineSelected={handleUpdateTimeLineSelected}
                        sampleRequestObj={sampleRequestObj}
                        gettingUserAccounts={gettingUserAccounts}
                        userName={userName}
                        loadingSamples={loadingSamples}
                        loadingSampleMessage={loadingSampleMessage}
                        searchVal={searchVal}
                        handleSearchVal={handleSearchVal}
                    />}
                />
            </div>
        </div>
    );
}

export default AppWrapper;